import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {

  showVideoUI = false;
  isYoutubeVideo = false;
  videoSrc = null;
  videoLoaded = false;
  backdrop = false;

  showHelpUI = '';
  loading = false;

  private reasons = [];
  private consultType = '';
  private aptSlotObj = null;
  private branchData = null;

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  toggleBackdrop(show: boolean) {
    if (show) {
      this.document.body.classList.add('backdrop');
      this.backdrop = true;
    } else {
      this.document.body.classList.remove('backdrop');
      this.backdrop = false;
    }
  }

  toggleLoading(show: boolean) {
    this.toggleBodyScroll(!show);
    this.loading = show;
  }

  toggleBodyScroll(scroll: boolean) {
    this.document.body.style.overflow = scroll ? 'auto' : 'hidden'
  }

  selectSlot(slot) {
    this.aptSlotObj = slot;
  }

  getSelectedSlot() {
    return this.aptSlotObj;
  }

  setSelectedBranch(branch) {
    this.branchData = branch;
  }

  selectConsultType(consult) {
    this.consultType = consult;
  }

  getConsultType() {
    return this.consultType;
  }

  getSelectedBranch() {
    return this.branchData;
  }

}
